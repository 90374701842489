<template>
  <div class="estimate">
    <Title />

    <section class="estimate-container">
      <div class="form-container">
        <div class="estimate-title" v-if="type === 'clean'">
          {{ $t("estimate_title_clean") }}
        </div>
        <div class="estimate-title" v-if="type === 'manage'">
          {{ $t("estimate_title_manage") }}
        </div>
        <v-form ref="contactForm" class="form">
          <div class="form-row">
            <!-- 氏名 -->
            <section class="form-names">
              <div class="form-sub-title">
                <span>{{ $t("estimate_name") }}</span>
                <span class="danger-text">
                  {{ $t("form_require") }}
                </span>
              </div>
              <v-text-field
                outlined
                dense
                :label="$t('estimate_name_placeholder')"
                v-model="name"
                :rules="rules.name"
              ></v-text-field>
            </section>
            <!-- ふりがな -->
            <section class="form-names">
              <div class="form-sub-title">
                <span>{{ $t("estimate_kata") }}</span>
                <span class="danger-text">
                  {{ $t("form_require") }}
                </span>
              </div>
              <v-text-field
                outlined
                dense
                :label="$t('estimate_kata_placeholder')"
                v-model="kata"
                :rules="rules.name_kata"
              ></v-text-field>
            </section>
          </div>
          <div class="form-row">
            <!--空き家の住所の所在地-->
            <section class="form-address">
              <div class="form-sub-title">
                <span>{{ $t("estimate_address") }}</span>
                <span class="danger-text">
                  {{ $t("form_require") }}
                </span>
              </div>
              <v-text-field
                outlined
                dense
                :label="$t('estimate_address_placeholder')"
                v-model="address"
                :rules="rules.address"
              />
            </section>
          </div>

          <div class="form-row">
            <!-- 電話番号 -->
            <section class="form-phone">
              <div class="form-sub-title">
                <span>{{ $t("estimate_phone") }}</span>
                <span class="danger-text">
                  {{ $t("form_require") }}
                </span>
              </div>
              <v-text-field
                outlined
                dense
                :label="$t('estimate_phone_placeholder')"
                v-model="phone"
                :rules="rules.phone"
              />
            </section>
            <!-- 曜日選択 -->
            <section
                class="form-select">
              <div class="form-sub-title">
                <span>{{ $t("estimate_weekday") }}</span>
              </div>
              <v-select
                :items="weeks"
                v-model="weekday"
                multiple
                dense
                outlined
              />
            </section>
            <!-- 時間選択 -->
            <section class="form-select">
              <div class="form-sub-title">
                <span>{{ $t("estimate_time") }}</span>
                <span class="danger-text">
                  {{ $t("form_require") }}
                </span>
              </div>
              <v-select multiple :items="times" v-model="time" dense outlined :rules="rules.time" />
            </section>
          </div>

          <div class="form-row details">
            <!--お問い合わせ内容-->
            <section class="form-details">
              <div class="form-sub-title">
                <span>{{ $t("estimate_details") }}</span>
                <span class="danger-text">
                  {{ $t("form_require") }}
                </span>
              </div>
              <div>
                <v-textarea
                  rows="3"
                  dense
                  outlined
                  :label="$t('form_placeholder')"
                  :rules="rules.details"
                  v-model="details"
                  counter="500"
                />
              </div>
            </section>

            <v-dialog v-model="confDialog" persistent max-width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="font-weight-black"
                  @click="checkForm()"
                  v-bind="attrs"
                  v-on="on"
                  color="#132d7a"
                  background-color="red"
                  rounded
                  bold
                  width="100px"
                >
                  <span style="color: #fff">{{ $t("form_send") }}</span>
                </v-btn>
              </template>

              <v-card>
                <div v-if="!errorFlag">
                  <v-card-title class="text-h5 pl-2">{{
                    $t("error_label")
                  }}</v-card-title>
                  <div class="pt-2 pl-2 danger-text">
                    {{ $t("validate_postCode") }}
                  </div>
                </div>

                <v-card-title v-else class="text-h5">
                  {{ $t("post_label") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn depressed @click="confDialog = false">
                    {{ !errorFlag ? $t("previos_btn_label") : $t("disagree") }}
                  </v-btn>
                  <v-btn v-if="errorFlag" color="#132d7a" @click="sendForm()">
                    <span style="color: #fff">{{ $t("agree") }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
              <div class="spiner" v-if="isSpiner">
                <v-progress-circular
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
            </v-dialog>
          </div>
        </v-form>
      </div>

      <div class="toTop">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              color="#132d7a"
              class="mb-3 ml-3 btn-text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon middium class="icon">{{ icons.home }}</v-icon>
              {{ $t("toTop_btn_label") }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              {{ $t("confirm_text") }}
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click="dialog = false">
                {{ $t("disagree") }}
              </v-btn>

              <v-btn color="#132d7a" @click="$router.push('/akiya')">
                <span style="color: #fff">{{ $t("agree") }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div class="previous-button mr-3" @click="$router.back()">
          <span class="previous-button-text">{{
            $t("previos_btn_label")
          }}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Title from "../components/title/Title.vue";
import { mdiHomeVariantOutline } from "@mdi/js";
import axios from "axios";

export default {
  name: "Estimate",
  props: ["type"],
  components: {
    Title,
  },
  data() {
    return {
      icons: {
        home: mdiHomeVariantOutline,
      },
      rules: {
        name: [
          (v) => !!v || "入力してください",
          (v) => (v && v.length <= 30) || "30文字以内で入力してください",
        ],
        name_kata: [
          (v) => !!v || "入力してください",
          (v) => /^[ァ-ンヴー]+$/.test(v) || "全角カタカナで入力してください",
          (v) => (v && v.length <= 30) || "30文字以内で入力してください",
        ],
        phone: [
          (v) => !!v || "入力してください",
          (v) =>
            /^(0{1}\d{9,10})$/.test(v) || "電話番号の形式が正しくありません",
        ],
        email: [
          (v) => !!v || "入力してください",
          (v) =>
            /.+@.+\..+/.test(v) ||
            "メールアドレスのフォーマットが正しくありません",
        ],
        address: [
          (v) => !!v || "入力してください",
          (v) => (v && v.length <= 255) || "255文字以内で入力してください",
        ],
        details: [
          (v) => !!v || "入力してください",
          (v) => (v && v.length <= 500) || "500文字以内で入力してください",
        ],
        time: [
          (v) => (v && v.length>0) || "選択してください",
        ]
      },
      errorFlag: false,
      name: "",
      kata: "",
      phone: "",
      email: "",
      address: "",
      weekday: "",
      time: "",
      details: "",
      dialog: false,
      confDialog: false,
      isSpiner: false,
      weeks: ["月曜", "火曜", "水曜", "木曜", "金曜", "土曜", "日曜"],
      times: [
        "9時 - 11時",
        "11時 - 13時",
        "13時 - 15時",
        "15時 - 17時",
        "17時 - 19時",
        "19時 - 21時",
      ],
    };
  },
  methods: {
    checkForm() {
      this.errorFlag = this.$refs.contactForm.validate();
    },
    sendForm() {
      const classification = this.type === "manage" ? "管理" : "片付け";
      if (this.$refs.contactForm.validate()) {
        const obj = {
          app: "55",
          record: {
            classification: {
              value: classification,
            },
            name: {
              value: this.name,
            },
            kata: {
              value: this.kata,
            },
            phone: {
              value: this.phone,
            },
            address: {
              value: this.address,
            },
            weekday: {
              value: this.weekday,
            },
            time: {
              value: this.time,
            },
            details: {
              value: this.details,
            },
            login_email: {
              value: sessionStorage.getItem("login_email"),
            },
          },
        };
        var data = JSON.stringify(obj);
        const key = "estimate";
        const url = `${this.$config.lambda_url}?key=${key}&data=${data}`;
        axios
          .get(url)
          .then(() => {
            this.$router.push("/send02");
            this.isSpiner = false;
          })
          .catch((error) => {
            this.isSpiner = false;
            alert("送信エラー: 管理者にお問い合わせください\n" + error);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.estimate {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "MS PGothic, 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;";

  .estimate-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    background-blend-mode: lighten;
    background-size: cover;
    background-position: center;

    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      padding: 20px;
      background-color: rgba(255, 255, 255);
      box-shadow: 0px 0px 30px 5px #e8f2f9;
      border-radius: 10px;
      z-index: 5;

      .estimate-title {
        font-size: max(22px, 2.5vw);
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
        color: #132d7a;
      }

      .form {
        display: flex;
        flex-direction: column;
        width: 80%;
        max-width: 800px;

        .form-row {
          display: flex;
          gap: 10px;
          align-items: flex-end;
        }

        .details{
          justify-content: space-between;
        }
        .form-sub-title {
          font-size: max(14px, 1.0vw);
          font-weight: bold;
          margin-bottom: 5px;
        }
        .form-names {
            width:30%
          
        }
        .form-address {
          flex-grow: 1;
        }
        .form-phone {
          flex-grow: 1;
        }
        .form-select {
          width:30%;
        }
        .form-details {
          flex-grow: 1;
          max-width: 80%;
        }

        .form_error {
          height: 20px;
          font-size: clamp(10px, 0.9vw);
          text-align: left;
          color: #c90000;
          margin-bottom: 20px;
        }

        .form-send {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          height: 100%;
        }
      }
    }

    .toTop {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      justify-content:space-between;

      .icon {
        margin-right: 5px;
      }
      .btn-text {
        font-size: 1.1em;
        display: flex;
        text-align: center;
      }

      .previous-button {
        margin-bottom: 10px;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 15%;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.5s;
        color: #fafafa;
        background-color: #676767;

        &:active {
          opacity: 0.6;
        }

        .previous-button-text {
          font-size: max(18px, 1.5vw);
          font-weight: bold;
          letter-spacing: 0.2rem;
        }
      }
    }
  }
}
</style>
